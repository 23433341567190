import { CustomerUpdateFormProvider } from '@jetshop/core/components/Auth/CustomerUpdateForm';
import t from '@jetshop/intl';
import React, { useState } from 'react';
import { GlobalError } from '../Forms/GlobalError';
import customerProfileQuery from './customerProfileQuery.gql';
import { BlockTitle, ButtonContainer, PrimaryButton } from './ProfilePage';
import { styled } from 'linaria/react';

import { Field } from 'formik';
import Checkbox from '@jetshop/ui/Checkbox';
import { Success } from '../Forms/Success';

const CheckboxContainer = styled('div')`
  label {
    margin-bottom: 1rem;
  }

  span {
    border-color: #202416 !important;
  }

  svg path {
    fill: #202416;
  }
`;

export function Communication() {
  const [success, setSuccess] = useState(false);

  // const { isSoftLoggedIn } = useLoginStatus({
  //   loginMutation: LoginMutation
  // });

  return (
    <div className="block" id="communication">
      <BlockTitle>{t('Communication')}</BlockTitle>
      <CustomerUpdateFormProvider
        customerProfileQuery={customerProfileQuery}
        onSubmit={() => {
          setSuccess(false);
        }}
        onSubmitSuccess={() => {
          setSuccess(true);
        }}
        render={({ isSubmitting, isValid, values, status }) => {
          return (
            <>
              <CheckboxContainer>
                <Field
                  name="preferences.type.acceptsEmail"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label={t('I want email')}
                      defaultChecked={!!values?.preferences?.type?.acceptsEmail}
                      // disabled={isSoftLoggedIn}
                    />
                  )}
                />
                <Field
                  name="preferences.type.acceptsSms"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label={t('I want sms')}
                      // disabled={isSoftLoggedIn}
                      defaultChecked={!!values?.preferences?.type?.acceptsSms}
                    />
                  )}
                />
              </CheckboxContainer>
              {status && status.globalError && (
                <GlobalError style={{ marginBottom: '2em' }}>
                  {status.globalError}
                </GlobalError>
              )}

              {success && (
                <Success style={{ marginBottom: '2em' }}>
                  {t('Your preferences have been updated.')}
                </Success>
              )}

              <ButtonContainer style={{ marginTop: '2rem' }}>
                <PrimaryButton
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  {t(isSubmitting ? 'Hold on a moment…' : 'Save')}
                </PrimaryButton>
              </ButtonContainer>
            </>
          );
        }}
      />
    </div>
  );
}
