import React from 'react';
import { css } from 'linaria';
import { NavLink } from 'react-router-dom';
import { theme } from '../Theme';
import { CustomerName } from './CustomerNameHeader';
import { baseRoute, myPagesRoutes } from './MyPages';
import { MyPagesBreadcrumbs } from './MyPagesBreadcrumbs';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useIntl } from '@jetshop/intl';
import { PageRouteNameWrapper } from './MyPages';

const sidebar = css`
  ${theme.below.sm} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
  }

  .nav-list {
    position: sticky;
    min-width: 223px;
    ${theme.below.sm} {
      min-width: 0px;
      width: 100%;
    }
  }

  h2 {
    font-weight: ${theme.fontWeights.semibold};
  }

  .nav-list {
    button {
      background: transparent;
    }
    .text-uppercase {
      a {
        text-transform: uppercase;
      }
    }
  }

  .nav-list {
    button {
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .nav-list a,
  .nav-list button {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    text-transform: none;
    text-decoration: none;
    color: ${theme.colors.black};
    margin-right: 1rem;
    border-bottom: 0.75px solid #dedede;
    height: 42px;
    background: transparent;
    width: 100%;

    svg {
      width: 17px;
      height: 17px;
      color: ${theme.colors.black};
    }

    &.active {
      font-weight: 600;
    }

    ${theme.below.sm} {
      background: #ffffff;
      border: 1px solid #dedede;
      margin: 6px 12px;
      padding: 16px;
    }
  }
`;

// Change me
const TempIcon = () => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
    viewBox="0 0 24 24"
    aria-labelledby="entranceIconTitle"
    stroke="#000"
    strokeWidth="1.411764705882353"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#000"
  >
    {' '}
    <title id="entranceIconTitle">
      Entrance
    </title> <path d="M11 15l3-3-3-3" /> <path d="M4.5 12H13" />{' '}
    <path strokeLinecap="round" d="M14 12h-1" /> <path d="M18 4v16H7V4z" />{' '}
  </svg>
);

const MyPagesSidebar = () => {
  const { logOut } = useAuth();
  const t = useIntl();
  return (
    <aside className={sidebar}>
      <MyPagesBreadcrumbs />
      <CustomerName />
      <ul className="nav-list">
        <h2 className="text-uppercase">
          <NavLink exact to={baseRoute.path}>
            {baseRoute.sideBarComponent}
          </NavLink>
        </h2>
        {myPagesRoutes.map(route => (
          <li key={route.path}>
            <NavLink to={route.path}>{route.sideBarComponent}</NavLink>
          </li>
        ))}
        <li>
          <button onClick={logOut}>
            <PageRouteNameWrapper>
              <TempIcon />
              {t('Sign out')}
            </PageRouteNameWrapper>
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default MyPagesSidebar;
